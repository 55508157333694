import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery, withPrefix } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'src/context/PageContext'
import { Context as ProfileContext } from 'src/context/ProfileContext'

const Meta = ({ title, description }) => {
    const { t } = useTranslation();
    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    siteUrl
                    supportedLanguages
                    title
                    placeholderImage: image
                    author
                }
            }
        }
    `);

    const { lang, matchPath, paths } = usePageContext();
    const {state: { profile }} = useContext(ProfileContext);

    const metaTitle = t('general.meta.title');
    const metaDescription = t('general.meta.description');

    const host = site.siteMetadata.siteUrl
    const ENScript = {
        src: withPrefix('scriptEN.js'),
        type: 'text/javascript'
    };
    const NLScript = {
        src: withPrefix('scriptNL.js'),
        type: 'text/javascript'
    };
    const cookieScript = lang === 'en' ? ENScript : NLScript;
    const languagePaths = paths || { en: '/', nl: '/', fr: '/' };
    const { placeholderImage, siteUrl, author } = site.siteMetadata
    const image = placeholderImage ? `${site.siteMetadata.siteUrl}${placeholderImage}` : null;
    const intercomScript = profile ? {
        type: 'text/javascript',
        innerHTML: `
window.intercomSettings = {
    app_id: "kpyx62hq",
    name: "${profile ? profile.first_name : null}",
    email: "${profile ? profile.email : null}",
    profile: "${profile ? (profile.student_profile ? 'student' : 'teacher') : 'visitor'}",
    created_at: "${profile ? profile.email_verified_at : null}"
};
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='//widget.intercom.io/widget/kpyx62hq';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
    } : null;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={metaTitle}
            titleTemplate={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:url`,
                    content: host,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:locale`,
                    content: lang,
                },
                {
                    name: `viewport`,
                    content: `width=device-width, initial-scale=1.0,maximum-scale=1.0`
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(
                placeholderImage
                    ? [
                        {
                            property: "og:image",
                            content: image,
                        },
                        {
                            property: "og:image:width",
                            content: '800px',
                        },
                        {
                            property: "og:image:height",
                            content: '800px',
                        },
                        {
                            name: "twitter:card",
                            content: "summary_large_image",
                        },
                    ]
                    : [
                        {
                            name: "twitter:card",
                            content: "summary",
                        },
                    ]
            )}
            link={[
                {
                    rel: 'canonical',
                    href: `${host}${matchPath}`,
                },
                {
                    rel: 'alternate',
                    hrefLang: 'x-default',
                    href: `${host}${matchPath}`,
                },
                ...site.siteMetadata.supportedLanguages.map(supportedLang => ({
                    rel: 'alternate',
                    hrefLang: supportedLang,
                    href: `${host}/${supportedLang}${languagePaths[supportedLang]}`,
                })),
            ]}
            script={[
                {
                    ...cookieScript
                },
                {
                    type: 'text/javascript',
                    src: '//cdn.iubenda.com/cs/iubenda_cs.js',
                    charset: 'UTF-8',
                    async: true
                },
                {
                    ...intercomScript
                },
                {
                    type: 'text/javascript',
                    src: '//widget.intercom.io/widget/kpyx62hq',
                    charset: 'UTF-8',
                    async: true
                },
            ]}
        >
            <meta name="description" content={t('general.meta.description')} />
            <meta name="robots" content={process.env.ENVIRONMENT !== 'production' ? "noindex,nofollow" : "all"} />
        </Helmet>
    )
}

export default Meta

