import { useTranslation } from 'react-i18next'
import routes from 'routes'
import MissingRouteDefinition from 'src/errors/MissingRouteDefinition'

export function localisedRoute(route, language, param) {
    const path = route.paths[language];
    if (!path) {
        throw MissingRouteDefinition.missingLocalizedPath(route, language);
    }

    if (param) {
        return `/${language}${path}/${param}`;
    }

    return `/${language}${path}`;
}

export default function useLocalizedRoutes() {
    const { i18n } = useTranslation();
    const { language } = i18n;

    return {
        localisedRoute,
        home: localisedRoute(routes.home, language),
        resetPassword: localisedRoute(routes.resetPassword, language),
        about: localisedRoute(routes.about, language),
        register: localisedRoute(routes.register, language),
        registerStudent: localisedRoute(routes.registerStudent, language),
        confirmEmailStudent: localisedRoute(routes.confirmEmailStudent, language),
        addStudentDetails: localisedRoute(routes.addStudentDetails, language),
        studentAccount: localisedRoute(routes.studentAccount, language),
        editStudentAccount: localisedRoute(routes.editStudentAccount, language),
        registerTeacher: localisedRoute(routes.registerTeacher, language),
        confirmEmailTeacher: localisedRoute(routes.confirmEmailTeacher, language),
        verifyEmailTeacher: localisedRoute(routes.verifyEmailTeacher, language),
        addTeacherDetails: localisedRoute(routes.addTeacherDetails, language),
        teacherRegistrationCompleted: localisedRoute(routes.TeacherRegistrationCompleted, language),
        findTeacher: localisedRoute(routes.findTeacher, language),
        howItWorks: localisedRoute(routes.howItWorks, language),
        faqs: localisedRoute(routes.faqs, language),
        teacherProfile: localisedRoute(routes.teacherProfile, language),
        bookTeacher: localisedRoute(routes.bookTeacher, language),
        termsAndConditions: localisedRoute(routes.termsAndConditions, language),
        bookingDetailsReview: localisedRoute(routes.bookingDetailsReview, language),
        bookingCompleted: localisedRoute(routes.bookingCompleted, language),
        bookTrial: localisedRoute(routes.bookTrial, language),
        teacherAccount: localisedRoute(routes.teacherAccount, language),
        privacyPolicy: localisedRoute(routes.privacyPolicy, language)
    };
}
