import React from 'react'
import 'src/components/atoms/Loader.css'

export default function Loader({ light }) {
    return (
        <div className={`loader${light ? ' light' :  ''}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}
