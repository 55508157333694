const path = require('path')

module.exports = {
    home: {
        component: path.resolve('./src/templates/Home.jsx'),
        paths: {
            en: '/',
            nl: '/',
            fr: '/',
        },
    },
    resetPassword: {
        component: path.resolve('./src/templates/Home.jsx'),
        paths: {
            en: '/reset-password/',
            nl: '/wachtwoord-aanpassen/',
            fr: '/reinitialiser-le-mot-de-passe/',
        },
    },
    howItWorks: {
        component: path.resolve("./src/templates/HowItWorks.jsx"),
        paths: {
            en: "/how-it-works",
            nl: "/hoe-het-werkt",
            fr: "/comment-ca-fonctionne",
        },
    },
    findTeacher: {
        component: path.resolve('./src/templates/FindTeacher.jsx'),
        paths: {
            en: '/find-teacher',
            nl: '/zoek-een-lesgever',
            fr: '/trouver-un-professeur',
        },
    },
    about: {
        component: path.resolve('./src/templates/About.jsx'),
        paths: {
            en: '/about-us',
            nl: '/over-ons',
            fr: '/a-propos-de-nous',
        },
    },
    faqs: {
        component: path.resolve('./src/templates/FAQs.jsx'),
        paths: {
            en: '/faqs',
            nl: '/faqs',
            fr: '/faqs',
        },
    },
    register: {
        component: path.resolve('./src/templates/Register.jsx'),
        paths: {
            en: '/register',
            nl: '/registreren',
            fr: '/enregistrer',
        },
    },
    registerStudent: {
        component: path.resolve('./src/templates/Student/Register.jsx'),
        paths: {
            en: '/student/register',
            nl: '/leerling/registreren',
            fr: '/etudiant/enregistrer',
        },
    },
    confirmEmailStudent: {
        component: path.resolve('./src/templates/Student/ConfirmEmail.jsx'),
        paths: {
            en: '/student/confirm-mail',
            nl: '/leerling/bevestig-email',
            fr: '/etudiant/bevestig-email',
        },
    },
    addStudentDetails: {
        component: path.resolve('./src/templates/Student/AddDetails.jsx'),
        paths: {
            en: '/student/add-details',
            nl: '/leerling/details-toevoegen',
            fr: '/etudiant/add-details',
        },
    },
    studentAccount: {
        component: path.resolve('./src/templates/Student/Account.jsx'),
        paths: {
            en: '/student/account',
            nl: '/leerling/profiel',
            fr: '/etudiant/profile',
        },
    },
    editStudentAccount: {
        component: path.resolve('./src/templates/Student/Edit.jsx'),
        paths: {
            en: '/student/edit-account',
            nl: '/leerling/bewerk-profiel',
            fr: '/etudiant/editer-profile',
        },
    },
    confirmEmailTeacher: {
        component: path.resolve('./src/templates/Teacher/ConfirmEmail.jsx'),
        paths: {
            en: '/teacher/confirm-mail',
            nl: '/lesgever/bevestig-email',
            fr: '/teacher/bevestig-email',
        },
    },
    verifyEmailTeacher: {
        component: path.resolve('./src/templates/Teacher/VerifyEmail.jsx'),
        paths: {
            en: '/teacher/verify-mail',
            nl: '/lesgever/email-bevestigd',
            fr: '/teacher/confirmation-email',
        },
    },
    registerTeacher: {
        component: path.resolve('./src/templates/Teacher/Register.jsx'),
        paths: {
            en: '/teacher/register',
            nl: '/lesgever/registreren',
            fr: '/teacher/register',
        },
    },
    teacherAccount: {
        component: path.resolve('./src/templates/Teacher/Account.jsx'),
        paths: {
            en: '/teacher/account',
            nl: '/lesgever/profiel',
            fr: '/teacher/profile',
        },
    },
    confirmTeacherStudent: {
        component: path.resolve('./src/templates/Teacher/ConfirmEmail.jsx'),
        paths: {
            en: '/teacher/confirm-mail',
            nl: '/lesgever/bevestig-email',
            fr: '/teacher/bevestig-email',
        },
    },
    addTeacherDetails: {
        component: path.resolve('./src/templates/Teacher/AddDetails.jsx'),
        paths: {
            en: '/teacher/add-details',
            nl: '/lesgever/details-toevoegen',
            fr: '/teacher/bevestig-email',
        },
    },
    TeacherRegistrationCompleted: {
        component: path.resolve('./src/templates/Teacher/RegistrationCompleted.jsx'),
        paths: {
            en: '/teacher/registration-completed',
            nl: '/lesgever/registration-completed',
            fr: '/teacher/registration-completed',
        },
    },
    teacherProfile: {
        component: path.resolve('./src/templates/Teacher/Profile.jsx'),
        paths: {
            en: '/teacher',
            nl: '/lesgever',
            fr: '/teacher',
        },
        matchPaths: {
            en: '/teacher/:id',
            nl: '/lesgever/:id',
            fr: '/teacher/:id',
        },
    },
    bookTeacher: {
        component: path.resolve('./src/templates/Booking/BookATeacher.jsx'),
        paths: {
            en: '/book-teacher',
            nl: '/boek-lesgever',
            fr: '/reserver-professeur',
        },
        matchPaths: {
            en: '/book-teacher/:id',
            nl: '/boek-lesgever/:id',
            fr: '/reserver-professeur/:id',
        }
    },
    bookTrial: {
        component: path.resolve('./src/templates/Booking/BookTrial.jsx'),
        paths: {
            en: '/book-trial',
            nl: '/boek-test-les',
            fr: '/reserver-professeur',
        },
        matchPaths: {
            en: '/book-trial/:id',
            nl: '/boek-test-les/:id',
            fr: '/reserver-professeur/:id',
        }
    },
    bookingDetailsReview: {
        component: path.resolve('./src/templates/Booking/BookingDetailsReview.jsx'),
        paths: {
            en: '/book-teacher/review-details',
            nl: '/boek-lesgever/review-details',
            fr: '/reserver-professeur/review-details',
        },
    },
    bookingCompleted: {
        component: path.resolve('./src/templates/Booking/BookingCompleted.jsx'),
        paths: {
            en: '/book-teacher/success',
            nl: '/boek-lesgever/succes',
            fr: '/reserver-professeur/success',
        },
    },
    termsAndConditions: {
        component: path.resolve('./src/templates/TermsAndConditions.jsx'),
        paths: {
            en: '/terms-and-conditions',
            nl: '/voorwaarden',
            fr: '/terms-and-conditions',
        },
    },
    privacyPolicy: {
        component: path.resolve('./src/templates/PrivacyPolicy.jsx'),
        paths: {
            en: '/privacy-policy',
            nl: '/privacy-voorwaarden',
            fr: '/privacy-policy',
        }
    }
}
