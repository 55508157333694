import React from 'react'
import classNames from 'classnames'
import Loader from 'src/components/atoms/Loader'

const DEFAULT = classNames(
    'appearance-none py-3 px-8 relative hover:opacity-90',
    'text-base font-bold font-general',
    'transition-all duration-200 focus:outline-none',
);
const PRIMARY = 'bg-dark-blue-600 text-white border border-dark-blue-600';
const SECONDARY = 'bg-alt-color-600 text-white';
const ZOOM = 'bg-zoom text-white';
const OUTLINE = 'bg-white text-dark-blue-600 border border-dark-blue-600';
const LIGHT = 'bg-white text-dark-blue-600';
const TRANSPARENT = '';

function Button(props) {
    const {
        children,
        className,
        isLoading,
        lightBtn,
        onClick,
        onMouseOver,
        onMouseOut,
        large,
        form,
        type
    } = props;
    const conditionalClasses = {
        'relative': isLoading,
        'text-lg': large,
    };

    return (
        <button
            type={type}
            form={form}
            className={classNames(DEFAULT, className, conditionalClasses)}
            disabled={isLoading}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            type={type}
        >
            <span className={isLoading ? 'opacity-0' : ''}>
                {children}
            </span>
            {isLoading ? (
                <div className="absolute inset-0 flex items-center justify-center"><Loader light={lightBtn} /></div>
            ) : null}
        </button>
    )
}

export function SubmitButton(props) {
    return <Button {...props} type="submit" className={classNames(PRIMARY, props.className)} />
}

export function SecondaryButton(props) {
    return <Button {...props} className={classNames(SECONDARY, props.className)} />
}

export function OutlinedButton(props) {
    return <Button {...props} className={classNames(OUTLINE, props.className)} lightBtn />
}

export function LightButton(props) {
    return <Button {...props} className={classNames(LIGHT, props.className)} lightBtn />
}

export function TransparentButton(props) {
    return <Button {...props} className={classNames(TRANSPARENT, props.className)} lightBtn />
}

export function ZoomButton(props) {
    return <Button {...props} className={classNames(ZOOM, props.className)} />
}

export default function PrimaryButton(props) {
    return <Button {...props} type="button" className={classNames(PRIMARY, props.className)} />
}
